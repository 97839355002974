import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { NavBar } from '../../components/segments/NavBar';
import { IndexLayout } from '../../layouts';
import { StaticImage } from 'gatsby-plugin-image';
import { NewAppText, NewAppTitle } from '../../components/ui';
import { theme } from '../../utils/theme';
import { desktopSize, laptopSize, mobileSize } from '../../utils';
import LetsConnectSection from '../../components/segments/LetsConnectSection';
import DarkFooter from '../../components/segments/DarkFooter';
import SwitchProject from '../../components/segments/SwitchProject';
import { Flex } from './imprint';

export default function () {
  return (
    <IndexLayout>
      <Helmet>
        <link rel="stylesheet" href="https://use.typekit.net/yxm3bov.css" />
      </Helmet>
      <NavBar alwaysOpaque invert />
      <PageContainer>
        <Hero>
          <HeroFlex>
            <StaticImage
              src="../../images/work/realtor/realtor-logo.png"
              alt="realtor logo"
              width={482}
              height={66}
              objectFit="contain"
              placeholder="blurred"
            />
            <HeroText>
              No matter the stage of the home journey, the Realtor.com app is designed to empower
              and make all things home simple, efficient, and enjoyable.
            </HeroText>
          </HeroFlex>
          <WidthContainer minWidth={525}>
            <StaticImage
              src="../../images/work/realtor/realtor-hero-phone.png"
              alt="preview of realtor on a phone with chevrons and house keys next to it"
              placeholder="blurred"
            />
          </WidthContainer>
          <Pin>
            <StaticImage
              src="../../images/work/realtor/map-pin.svg"
              alt="red map pin with white border"
              width={400}
              objectFit="contain"
              placeholder="blurred"
            />
          </Pin>
        </Hero>
        <BackgroundWrapper>
          <InteriorContainer>
            <StaticImage
              src="../../images/work/realtor/realtor-home.png"
              alt="interior of a modern house dining room"
              placeholder="blurred"
            />
          </InteriorContainer>
          <Map>
            <StaticImage
              src="../../images/work/realtor/map-bg.png"
              alt="map with pins on it"
              height={900}
              // objectFit="contain"
              placeholder="blurred"
            />
          </Map>
          <MainContainer>
            <Flex align="flex-start" justify="space-between">
              <WidthContainer minWidth={450}>
                <StaticImage
                  src="../../images/work/realtor/realtor-phone.png"
                  alt="preview of realtor on a phone"
                  width={450}
                  // height={800}
                  objectFit="contain"
                  placeholder="blurred"
                />
              </WidthContainer>
              <WidthContainer minWidth={350}>
                <Flex direction="column" align="flex-start">
                  <TextGrid>
                    <div>
                      <MarginText marginBottom={4}>
                        <b>Industry</b>
                      </MarginText>
                      <NewAppText color={theme.MAIN_DARK_BLUE}>Real Estate</NewAppText>
                    </div>
                    <div>
                      <MarginText marginBottom={4}>
                        <b>Technology Utilized</b>
                      </MarginText>
                      <NewAppText color={theme.MAIN_DARK_BLUE}>Strategy</NewAppText>
                      <NewAppText color={theme.MAIN_DARK_BLUE}>iOS Development</NewAppText>
                      <NewAppText color={theme.MAIN_DARK_BLUE}>Android Development</NewAppText>
                      <NewAppText color={theme.MAIN_DARK_BLUE}>Flutter Development</NewAppText>
                    </div>
                  </TextGrid>
                  <Hr />
                  <NewAppTitle color={theme.REALTOR_RED}>The Challenge</NewAppTitle>
                  <Paragraph>
                    Whether you're looking to rent, a first-time homebuyer, a seasoned homeowner, or
                    simply interested in a home's estimated value, chances are you need a robust set
                    of tools and a guiding hand to help you through the process. In an ever-changing
                    competitive market, Realtor.com wanted to create a world-class mobile app
                    experience that was not only fun and easy to use but could scale with an
                    aggressive corporate growth strategy.
                  </Paragraph>
                </Flex>
              </WidthContainer>
            </Flex>
            <SolutionContainer align="flex-end" justify="space-between">
              <Flex direction="column" align="flex-start">
                <NewAppTitle color={theme.REALTOR_RED}>The Solution</NewAppTitle>
                <WidthContainer maxWidth={525}>
                  <Paragraph>
                    Through an accelerated research and discovery process, Rapptr provided
                    Realtor.com with a mobile strategy that fostered a culture of experimentation
                    and collaboration. Rapptr deployed a turnkey, dedicated native mobile team to
                    complement existing in-house efforts. This accelerated native mobile development
                    with over 7,500 hours of native mobile bandwidth. The result? Users can now
                    enjoy a beautiful and functional home search experience.
                  </Paragraph>
                </WidthContainer>
                <ImpactContainer>
                  <NewAppTitle color={theme.REALTOR_RED}>Impact</NewAppTitle>
                  <ImpactData>
                    <div>
                      <ImpactText>#25</ImpactText>
                      <MarginText marginBottom={0}>
                        <b>on the Lifestyle Chart</b>
                      </MarginText>
                    </div>
                    <div>
                      <ImpactText>317K</ImpactText>
                      <MarginText marginBottom={0}>
                        <b>ratings</b>
                      </MarginText>
                    </div>
                    <div>
                      <ImpactText>4.7</ImpactText>
                      <StaticImage
                        src="../../images/work/realtor/realtor-ratings.svg"
                        alt="4.7 stars"
                        width={100}
                        height={17}
                        objectFit="contain"
                        placeholder="blurred"
                      />
                    </div>
                  </ImpactData>
                </ImpactContainer>
              </Flex>
              <MobileBgDiv>
                <MobileMapContainer>
                  <StaticImage
                    src="../../images/work/realtor/map-bg.png"
                    alt="map with pins on it"
                    // height={800}
                    // width={500}
                    // objectFit="cover"
                    placeholder="blurred"
                  />
                </MobileMapContainer>
                <WidthContainer mobileMinWidth={70}>
                  <StaticImage
                    src="../../images/work/realtor/realtor-phone-map.png"
                    alt="realtor app showing map with pins"
                    width={420}
                    placeholder="blurred"
                  />
                </WidthContainer>
              </MobileBgDiv>
            </SolutionContainer>
          </MainContainer>
        </BackgroundWrapper>
        <SwitchProject page="realtor" />
      </PageContainer>
      <LetsConnectSection />
      <DarkFooter />
    </IndexLayout>
  );
}

const PageContainer = styled.div`
  position: relative;
  /* min-height: 500px; */
  width: 100%;
  background: radial-gradient(50% 50% at 50% 0%, #fff 45%, #d7dee1 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 120px;
  @media ${laptopSize} {
    margin-top: 80px;
  }
`;

const Hero = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  /* height: calc(100vh + 250px); */
  min-height: 1050px;
  max-width: 1175px;
  width: 80%;
  margin-top: 120px;
  @media screen and (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
    /* height: 100vh; */
    min-height: 0;
    height: auto;
    margin-top: 40px;
    padding-top: 12px;
  }
`;

const HeroFlex = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media screen and (max-width: 1024px) {
    align-items: center;
    justify-content: center;
  }
`;

const HeroText = styled(NewAppText as any)`
  margin-top: 80px;
  font-size: 28px;
  line-height: 46px;
  color: ${theme.MAIN_DARK_BLUE};
  font-weight: 300;
  letter-spacing: 0;
  @media screen and (max-width: 1024px) {
    margin-top: 60px;
    font-size: 21px;
    line-height: 32px;
    text-align: center;
  }
`;

const Pin = styled.div`
  position: absolute;
  bottom: 10%;
  left: 100px;
  transform: scale(0.4);
  z-index: 10;
  @media screen and (max-width: 1024px) {
    bottom: -50%;
    left: 0;
  }
  @media ${mobileSize} {
    transform: scale(0.3);
  }
`;

const BackgroundWrapper = styled.div`
  position: relative;
  margin-top: -500px;
  padding-top: 400px;
  padding-bottom: 150px;
  width: 100%;

  background-color: ${theme.WHITE_COLOR};
  -webkit-clip-path: polygon(50% 0%, 100% 26%, 100% 100%, 0 100%, 0% 26%);
  clip-path: polygon(50% 0%, 100% 26%, 100% 100%, 0 100%, 0% 26%);

  @media ${desktopSize} {
    -webkit-clip-path: polygon(50% 0, 100% 20%, 100% 100%, 0 100%, 0 20%);
    clip-path: polygon(50% 0, 100% 20%, 100% 100%, 0 100%, 0 20%);
  }

  @media screen and (max-width: 1024px) {
    margin-top: 100px;
    padding-top: 200px;
    padding-bottom: 50px;
    -webkit-clip-path: polygon(50% 0, 100% 10%, 100% 100%, 0 100%, 0 10%);
    clip-path: polygon(50% 0, 100% 10%, 100% 100%, 0 100%, 0 10%);
  }

  @media ${mobileSize} {
    -webkit-clip-path: polygon(50% 0, 100% 4%, 100% 100%, 0 100%, 0 4%);
    clip-path: polygon(50% 0, 100% 4%, 100% 100%, 0 100%, 0 4%);
  }
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1100px;
  width: 80%;
  margin: 0 auto;
  color: ${theme.MAIN_DARK_BLUE};
  @media ${laptopSize} {
    width: 95%;
  }
`;

const Map = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -1;
  @media ${laptopSize} {
    display: none;
  }
`;

const InteriorContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  -webkit-clip-path: polygon(0 0, 100% 25%, 100% 75%, 0% 100%);
  clip-path: polygon(0 0, 100% 25%, 100% 75%, 0% 100%);
  max-width: 35%;
  @media screen and (max-width: 638px) {
    top: 0px;
    clip-path: none;
    -webkit-clip-path: none;
    max-width: 100vw;
    width: 100vw;
    max-height: 300px;
    overflow: hidden;
    img {
      object-fit: contain;
    }
  }
`;

const MobileBgDiv = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  width: 100vw;
  padding-bottom: 32px;
`;

const MobileMapContainer = styled.div`
  display: none;
  position: absolute;
  bottom: -20px;
  left: 0;
  right: 0;
  width: 100vw;
  height: 600px;
  overflow: hidden;
  div {
    transform: scale(1.75);
  }
  @media ${laptopSize} {
    display: block;
  }
  @media ${mobileSize} {
    bottom: 0px;
    height: 400px;
  }
`;

interface WidthProps {
  minWidth?: number;
  maxWidth?: number;
  mobileMinWidth?: number; // vw
}
const WidthContainer = styled.div<WidthProps>`
  /* width: 100%; */
  position: relative;
  min-width: ${(props) => props.minWidth}px;
  max-width: ${(props) => (props.maxWidth ? `${props.maxWidth}px` : '90%')};
  height: max-content;
  @media ${laptopSize} {
    display: flex;
    justify-content: center;
    min-width: 80vw;
  }
  @media ${mobileSize} {
    min-width: ${(props) => props.mobileMinWidth ?? 80}vw;
    max-width: ${(props) => props.mobileMinWidth ?? 100}vw;
  }
`;

const SolutionContainer = styled(Flex)`
  margin-top: -7vw;
  @media ${desktopSize} {
    margin-top: 20px;
  }
  @media ${laptopSize} {
    margin-top: 0;
    display: flex;
    flex-direction: column-reverse;

    h1 {
      margin-top: 36px;
    }
  }
`;

const Paragraph = styled(NewAppText)`
  color: ${theme.MAIN_DARK_BLUE};
  font-size: 18px;
  @media ${laptopSize} {
    text-align: center;
  }
`;

const TextGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  p {
    margin-bottom: 0;
    font-size: 18px;
  }
  @media ${laptopSize} {
    grid-template-columns: 1fr;
    grid-template-rows: 70px 1fr;
    margin-top: 60px;
    p {
      text-align: center;
    }
  }
`;

const Hr = styled.hr`
  max-width: 450px;
  height: 2px;
  border-color: ${theme.REALTOR_RED};
  @media ${laptopSize} {
    width: 80%;
  }
`;

const ImpactContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 36px;
  border-radius: 10px;
  background-color: ${theme.WHITE_COLOR};
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);

  div:nth-child(3) > p {
    margin-right: 0px;
  }

  @media ${laptopSize} {
    flex-direction: column;
    align-items: center;
    padding: 0;
    box-shadow: none;
  }
`;

const ImpactData = styled.div`
  display: flex;
  align-items: flex-start;
  @media ${laptopSize} {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`;

const ImpactText = styled.p`
  /* margin-bottom: 10px; */
  margin-right: 70px;
  font-family: ${theme.SECONDARY_FONT};
  font-size: 56px;
  font-weight: 700;
  line-height: 56px;

  @media ${laptopSize} {
    margin-right: 0;
  }
`;

interface MarginProps {
  marginBottom?: number;
  marginLeft?: number;
  marginRight?: number;
  mobileMarginBottom?: number;
}
const MarginText = styled(NewAppText)<MarginProps>`
  margin-bottom: ${({ marginBottom }) => (marginBottom !== undefined ? marginBottom : 16)}px;
  margin-left: ${({ marginLeft }) => (marginLeft ? marginLeft : 0)}px;
  margin-right: ${({ marginRight }) => (marginRight ? marginRight : 0)}px;

  color: ${theme.MAIN_DARK_BLUE};
  @media ${laptopSize} {
    /* margin: 0 auto; */
    text-align: center;
    margin-bottom: ${({ mobileMarginBottom }) =>
      mobileMarginBottom !== undefined ? mobileMarginBottom : 32}px;
  }
`;
